import styles from "./Warscroll.module.scss";

const Weapons = ({weapons, type}) => {
    if (weapons.length < 1) return null;
    return <table className={styles.table}>
        <thead>
        <tr>
            <th>{type}</th>
            {type === "Ranged Weapons" ? <th>Range</th> : null}
            <th>Attacks</th>
            <th>Hit</th>
            <th>Wound</th>
            <th>Rend</th>
            <th>Damage</th>
        </tr>
        </thead>
        <tbody>
        {weapons.map(weapon => <tr>
            <td><div className={styles.weaponName}>{weapon.name}</div> {weapon.abilities?.map(a=><div className={styles.weaponAbility}>{a}</div>)}</td>
            {weapon.range ? <td>{weapon.range}</td> : null}
            <td>{weapon.attacks}</td>
            <td>{weapon.hit}</td>
            <td>{weapon.wound}</td>
            <td>{weapon.rend}</td>
            <td>{weapon.damage}</td>
        </tr>)}
        </tbody>
    </table>;

}
export default Weapons
