import Warscroll from './Warscroll'
import * as React from "react";
import {useCallback, useState} from "react";
import styles from './Warscroll.module.scss';
import Ability from "./Ability";
import {
    Autocomplete,
    Fab,
    FormControlLabel,
    IconButton,
    InputLabel,
    Switch,
    TextField,
    useMediaQuery
} from "@mui/material";
import {KofiButton} from "react-kofi-button";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {Brightness4, Brightness7} from "@mui/icons-material";
import reactStringReplace from 'react-string-replace';

export const replaceNewLines = (text)=>reactStringReplace(text,/(\n)/g,(match,i)=><br/>)

const phaseOrder = ["startOfTurn", "heroPhase", "movementPhase", "shootingPhase", "chargePhase", "combatPhase", "endOfTurn", "defensive"]

const groupByPhase = (list) => {
    return {
        startOfTurn: list.filter(a => a.phase === "startOfTurn"),
        heroPhase: list.filter(a => a.phase === "heroPhase"),
        movementPhase: list.filter(a => a.phase === "movementPhase"),
        shootingPhase: list.filter(a => a.phase === "shootingPhase"),
        chargePhase: list.filter(a => a.phase === "chargePhase"),
        combatPhase: list.filter(a => a.phase === "combatPhase"),
        endOfTurn: list.filter(a => a.phase === "endOfTurn"),
        defensive: list.filter(a => a.phase === "defensive"),
    }
}

const AoIHeader = ({metadata}) => {
    return <>
        <h1>Age of Index</h1>
        <h3>This is cobbled together from the AoS4 app (data version {metadata.data_version}) and will only be the indexes that have been released for
            free <KofiButton username="ageofindex" preset="skinny" backgroundColor="kofiGrey" label="Tip me"/>
        </h3>
    </>;
}

const Warscrolls = ({warscrollData, abilityData, metadata}) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const [justAbilities, setJustAbilities] = useState(false)
    const [showBattleTraits, setShowBattleTraits] = useState(false)
    const [showSpellLores, setShowSpellLores] = useState(false)

    const [scrollIds, setScrollIds] = useState([]);
    const [faction, setFaction] = useState("");
    const [ror, setRor] = useState()
    const [heroicTrait, setHeroicTrait] = useState()
    const [artefactOfPower, setArtefactOfPower] = useState()
    const [battleFormation, setBattleFormation] = useState()
    const [darkMode, setDarkMode] = useState(prefersDarkMode);

    const checkBattleTraits = () => {
        setShowBattleTraits(!showBattleTraits)
    }
    const checkAbilities = () => {
        setJustAbilities(!justAbilities)
    }

    const checkSpellLores = () => {
        setShowSpellLores(!showSpellLores)
    }

    const resetState = () => {
        setHeroicTrait(null)
        setArtefactOfPower(null)
        setBattleFormation(null)
        setRor(null)
        setScrollIds([])
    }
    const askPermission = useCallback(async queryName => {
        try {
            const permissionStatus = await navigator.permissions.query(queryName)
            return permissionStatus.state === 'granted'
        } catch (error) {
            // Browser compatibility / Security error (ONLY HTTPS) ...
            return false
        }
    }, [])


    const scrollDetails = warscrollData.map(scroll => ({
        ...scroll,
        display: scroll.name
    })).filter(scroll => scrollIds.includes(scroll.id) || ror?.regimentOfRenownWarscrolls?.includes(scroll.id))

    const options = warscrollData
        .filter(scroll => scroll.faction.includes(faction) || (scroll.faction.length === 0 && !faction))
        .map(scroll => ({...scroll, display: scroll.name}))
    const factionOptions = [...new Set(warscrollData.flatMap(scroll => scroll.faction))]
        .filter(f => !f.includes("Grand Alliance"))
        .sort()
    const abilities = scrollDetails
        .flatMap(scroll => scroll.abilities.map(a => ({...a, unitName: scroll.name})))
        .sort((a, b) => a.phaseDetails.localeCompare(b.phaseDetails))
        .sort((a, b) => phaseOrder.indexOf(a.phase) - phaseOrder.indexOf(b.phase))

    const importList = useCallback(async () => {
        const hasReadPermission = await askPermission({name: 'clipboard-read'})
        if (hasReadPermission) {
            const list = await navigator.clipboard.readText()
            if (list) {
                const foundFaction = factionOptions.find(fact => list.includes(fact));
                if (foundFaction) {
                    setFaction(foundFaction)
                    setScrollIds(warscrollData.filter(scroll => scroll.faction.includes(foundFaction) && list.includes(scroll.name)).map(s => s.id))
                    setHeroicTrait(abilityData.heroicTraits.filter(ht => foundFaction === (ht.faction)).flatMap(ht => ht.abilities).find(a => list.includes(a.name)))
                    setBattleFormation(abilityData.battleFormations.filter(ht => foundFaction === (ht.faction)).find(a => list.includes(a.name)))
                    setArtefactOfPower(abilityData.artefactsOfPower.filter(ht => foundFaction === (ht.faction)).flatMap(ht => ht.abilities).find(a => list.includes(a.name)))
                    setRor(abilityData.regimentOfRenown.filter(ror => ror.regimentOfRenownFactions.includes(foundFaction)).find(r => list.includes(r.name)))
                }
            }
        }
    }, [askPermission, abilityData, warscrollData, factionOptions])


    const rors = abilityData.regimentOfRenown.filter(ror => ror.regimentOfRenownFactions.includes(faction))
    const heroicTraits = abilityData.heroicTraits.filter(ht => faction === (ht.faction))
    const artefactsOfPower = abilityData.artefactsOfPower.filter(ap => faction === (ap.faction))
    const battleTraits = abilityData.battleTraits.filter(ap => faction === (ap.faction)).filter(_ => showBattleTraits)
    const battleFormations = abilityData.battleFormations.filter(ap => faction === (ap.faction))
    const spellLores = abilityData.lores.filter(ap => faction === (ap.faction)).filter(_ => showSpellLores)

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    }

    const darkTheme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
            ...(darkMode ? {
                info: {main: "rgba(255,204,0,0.27)"}
            } : {
                info: {main: "#d7cfc4"}
            })
        },
    });

    const allAbilities = abilities
        .map(a => ({...a, source: a.unitName}))
        .concat({...heroicTrait, source: "Heroic Trait"})
        .concat({...artefactOfPower, source: "Artefact"})
        .concat({...battleFormation, source: "Battle Formation"})
        .concat(ror ? ror.abilities.map(a => ({...a, source: "Regiment of Renown"})) : [])
        .concat(battleTraits.flatMap(bt => bt.abilities.map(a => ({...a, source: "Battle Trait"}))))
        .concat(spellLores.flatMap(lore => lore.spells.map(a => ({...a, source: lore.name}))))
        .sort((a, b) => a.phaseDetails?.localeCompare(b?.phaseDetails))
        .sort((a, b) => phaseOrder.indexOf(a?.phase) - phaseOrder.indexOf(b?.phase))
    ;
    const groupedAbilities = groupByPhase(allAbilities)
    return <ThemeProvider theme={darkTheme}>
        <CssBaseline/>
        <div className={styles.app}>
            <AoIHeader metadata={metadata}/>
            <div className={styles.select}>
                <div className={styles.selectGroup}>
                    <InputLabel id="faction-label">Faction</InputLabel>
                    <Autocomplete
                        value={faction || null}
                        id={"faction"}
                        renderInput={(params) => <TextField {...params} placeholder={"Faction"}/>}
                        onChange={(event, value) => {
                            setFaction(value);
                            resetState();
                        }}
                        options={factionOptions}
                    />
                </div>
                <div className={styles.selectGroup}>
                    <InputLabel id="unit-label">Units</InputLabel>
                    <Autocomplete
                        multiple
                        id="unit-selector"
                        options={options}
                        getOptionLabel={(option) => option.display}
                        value={options.filter(o => scrollIds.includes(o.id))}
                        filterSelectedOptions
                        onChange={(event, value) => {
                            setScrollIds(value.map(v => v.id))
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Units"
                            />
                        )}
                    />
                </div>
                <div className={styles.selectGroup}>
                    <InputLabel id="ror-label">Regiment Of Renown</InputLabel>
                    <Autocomplete
                        value={ror || null}
                        id={"ror"}
                        getOptionLabel={(option) => `${option.name} (${option.regimentOfRenownPointsCost})`}
                        renderInput={(params) => <TextField {...params} placeholder={"Regiment"}/>}
                        onChange={(_, value) => setRor(value)}
                        options={rors}
                    />
                </div>
            </div>
            <div className={styles.select}>
                <div className={styles.selectGroup}>
                    <InputLabel id="heroic-trait-label">Heroic Trait</InputLabel>
                    <Autocomplete
                        value={heroicTrait || null}
                        id={"heroicTrait"}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} placeholder={"Heroic Trait"}/>}
                        onChange={(_, value) => setHeroicTrait(value)}
                        options={heroicTraits?.flatMap(ht => ht.abilities)}
                    />
                </div>
                <div className={styles.selectGroup}>
                    <InputLabel id="artefact-of-power-label">Artefact Of Power</InputLabel>
                    <Autocomplete
                        value={artefactOfPower || null}
                        id={"artefact-of-power"}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} placeholder={"Faction"}/>}
                        onChange={(_, value) => setArtefactOfPower(value)}
                        options={artefactsOfPower?.flatMap(ht => ht.abilities)}
                    />
                </div>
                <div className={styles.selectGroup}>
                    <InputLabel id="battle-formation-label">Battle Formation</InputLabel>
                    <Autocomplete
                        id="battle-formation-selector"
                        options={battleFormations}
                        getOptionLabel={(option) => option.name}
                        value={battleFormation || null}
                        onChange={(_, value) => setBattleFormation(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Battle Formation"
                            />
                        )}
                    />
                </div>
            </div>
            <div>
                <FormControlLabel control={<Switch
                    checked={showBattleTraits}
                    onChange={checkBattleTraits}
                />} label={"Show Battle Traits"}/>
                <FormControlLabel control={<Switch
                    checked={showSpellLores}
                    onChange={checkSpellLores}
                />} label={"Show Spell/Prayer Lores"}/>
                <FormControlLabel control={<Switch
                    checked={justAbilities}
                    onChange={checkAbilities}
                />} label={"Just abilities"}/>
            </div>

            {justAbilities ? null : scrollDetails.map(scroll => <Warscroll scroll={scroll}/>)}
            <div className={styles.justAbilities}>
                {justAbilities ? [...Object.entries(groupedAbilities)].map(([phase, abilities]) => {
                    return <div className={styles.abilityList}>
                        {abilities.map(a => <Ability ability={a} source={a.source}/>)}
                    </div>;
                }) : null}
                {!justAbilities && (heroicTrait || artefactOfPower || battleFormation) ? <div><h2>Enhancements</h2>
                    <div className={styles.abilityList}>
                        {heroicTrait ? <Ability ability={heroicTrait} source={"Heroic Trait"}/> : null}
                        {artefactOfPower ? <Ability ability={artefactOfPower} source={"Artefact Of Power"}/> : null}
                        {battleFormation ? battleFormation?.abilities?.map(a => <Ability ability={a}
                                                                                         source={"Battle Formation"}/>) : null}
                    </div>
                </div> : null}
                {!justAbilities && ror ?
                    <div>
                        <h2>Regiment Of Renown</h2>
                        <div className={styles.abilityList}>
                            {ror?.abilities?.map(a => <Ability ability={a} source={"Regiment of Renown"}/>)}
                        </div>
                    </div> : null}

                {showBattleTraits && !justAbilities ? <div>
                    <h2>Battle Traits</h2>
                    <div className={styles.abilityList}>
                        {battleTraits.map(ht => ht.abilities.map(a => <Ability ability={a}
                                                                               source={ht.faction}/>))}
                    </div>
                </div> : null}
                {showSpellLores && !justAbilities ? spellLores.map(lore => <div>
                    <h2>{lore.name}</h2>
                    <div className={styles.abilityList}>
                        {lore.spells.map(a => <Ability ability={a} source={lore.name}/>)}
                    </div>
                </div>) : null}
            </div>

            <Fab variant="extended" onClick={importList}>
                Import List From Clipboard
            </Fab>
            <IconButton sx={{ml: 1}} onClick={toggleDarkMode} color="inherit">
                {darkMode ? <Brightness7/> : <Brightness4/>}
            </IconButton>
        </div>
    </ThemeProvider>;
}

export default Warscrolls